import React from "react"
import PropTypes from "prop-types"

import Header from "../Header"
import Footer from "../Footer"
import '@sass/main.scss'
import './index.scss'
import SEO from "../seo"

const Layout = ({ children }) => {
  return (
    <div className='layout'>
      <SEO></SEO>
      <Header />
      {children}
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
