import React from "react"
import PropTypes from "prop-types"

import './index.scss'

const Content = ({ children }) => {
    return (
        <main className="content">{children}</main>
    )
}

Content.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Content