import React from "react"
import Nav from '@components/Nav';
import Logo from '@assets/logo-inline.svg';
import { Link } from "gatsby"

import './index.scss'

const Header = () => (
  <header
    className="header"
  >
    <div className="header__wrapper">
      <Nav/>
      <h1 className="header__logo">
        <Link to="/">
          <Logo />
        </Link>
      </h1>
    </div>
  </header>
)

export default Header
