import React from "react"

import './index.scss'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__info">
          <p className="footer__copy">Property Center © {new Date().getFullYear()}</p>
          <p>Kontakt:<br />
            <strong>E-mail:</strong> <a className="footer__link" href="mailto:biuro@propertycenter.pl">biuro@propertycenter.pl</a><br />
            <strong>Administrator:</strong> <a className="footer__link" href="tel:660281681">tel. 660281681</a><br /><strong>Serwis techniczny 7-15:</strong> <a className="footer__link" href="tel:788833889">tel. 788833889</a><br /><strong>Serwis techniczny 24/7:</strong> <a className="footer__link" href="tel:660281481">tel. 660281481</a><br /><br />
    Siedziba Spółki: Property Center Sp. z o.o. ul. Malborska 130, 30-624 Kraków KRS 0000777337,<br /> NIP: 6793182311, REGON 382846904
          </p>
        </div>
        <div className="footer__author">
          realizacja: <a className="footer__link" rel="noopener noreferrer" href="https://kubarazny.pl" target="_blank">KubaRazny.pl</a>
        </div>
        <a target="_blank" href="https://wizytowka.rzetelnafirma.pl/QOAIAVYJ" rel="nofollow noopener noreferrer">
          <img width="160" height="75" title="Kliknij i sprawdź status certyfikatu" alt="" src="https://aktywnybaner.rzetelnafirma.pl/ActiveBanner/GetActiveBannerImage/3/QOAIAVYJ" />
        </a>
      </div>
    </footer>
  )
}


export default Footer
